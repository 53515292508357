import styled from "@emotion/styled"
import * as React from "react"
import { Select, MenuItem } from "@mui/material"
import { addAlpha, Color } from "../../../util/Color"

const content = [
  {val: 'juggle', text: "Juggleについてのお問い合わせ"},
  {val: '案件', text: "案件のご相談"},
  {val: '協業', text: "協業のご相談"},
  {val: 'プレス', text: "プレスリリースについてのお問い合わせ"},
  {val: '採用', text: "採用"},
  {val: 'その他', text: "その他のご相談・お問い合わせ"},
]

const TextField = ({ label, text, setText}) => {
  const width = label.length * 18
  const right = 80 + (180 - width)
  return (
    <Wrap>
      <Label right={right} width={width}>{label}</Label>
      <SSelect
            displayEmpty
            value={text}
            fullWidth
            onChange={(e) => setText(e.target.value)}
            renderValue={(selected) => {
              if (selected.length === 0) {
                return <Placeholder>選択してください</Placeholder>
              }

              return selected
            }}
          >
            {content.map((c) => <SMenuItem key={c.val} value={c.text}>{c.text}</SMenuItem>)}
      </SSelect>
    </Wrap>
  )
}

export default TextField

const Label = styled('div')(({width, right}) => ({
  minWidth: `${width}px`,
  fontSize: '18px',
  margin: '15px 0px',
  fontFamily: 'Noto Sans JP',
  marginRight: right
}))

const Wrap = styled('div')({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'space-between',
  width: '800px',
  margin: '40px 0px'
})
const Placeholder = styled('div')({
  color: addAlpha(Color.black, 0.42),
  fontSize: '16px',

})

const SSelect = styled(Select)({
  background: 'white',
  borderRadius: '8px',
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
})

const SMenuItem = styled(MenuItem)({
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
})