import styled from "@emotion/styled"
import * as React from "react"
import MTextField from '@mui/material/TextField'
import { addAlpha, Color } from "../../../util/Color"


const TextField = ({ label, setText, row = 1, error = false, multiline= false, helperText = '' }) => {
  return (
    <Wrap>
      <Label>{label}</Label>
      <STextField variant="outlined" onChange={(e) => setText(e.target.value)} multiline={multiline} placeholder="入力してください" rows={row} fullWidth error={error} helperText={helperText} />
    </Wrap>
  )
}

export default TextField

const Label = styled('div')(({}) => ({
  fontSize: '12px',
  margin: '12px 0px 8px',
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
}))

const Wrap = styled('div')({
})

const STextField = styled(MTextField)({
  background: 'white',
  borderRadius: '8px',
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
  '> div' : {
    borderRadius: '8px',
    height: '100%',
    color: Color.black,
    fontFamily: 'Noto Sans JP',
    fontFeatureSettings: '"palt" 1',
    fontSize: '14px',
    '> textarea::placeholder': {
      fontSize: '14px',
      color: Color.black
    }
  },
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      // borderColor: Color.orange,
    },
  },
})