import styled from "@emotion/styled"
import * as React from "react"
import MTextField from '@mui/material/TextField'
import { addAlpha, Color } from "../../../util/Color"


const TextField = ({ label, setText, row = 1, error = false, multiline= false, helperText = '' }) => {
  const width = label.length * 18
  const right = 80 + (180 - width)
  return (
    <Wrap>
      <Label right={right} width={width}>{label}</Label>
      <STextField variant="outlined" onChange={(e) => setText(e.target.value)} multiline={multiline} placeholder="入力してください" rows={row} fullWidth error={error} helperText={helperText} />
    </Wrap>
  )
}

export default TextField

const Label = styled('div')(({width, right}) => ({
  minWidth: `${width}px`,
  fontSize: '18px',
  margin: '15px 0px',
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
  marginRight: right
}))

const Wrap = styled('div')({
  display: 'flex',
  // alignItems: 'center',
  justifyContent: 'space-between',
  width: '800px',
  margin: '40px 0px'
})

const STextField = styled(MTextField)({
  background: 'white',
  borderRadius: '8px',
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
  '> div' : {
    borderRadius: '8px',
    height: '100%',
    color: Color.black,
    fontFamily: 'Noto Sans JP',
    fontFeatureSettings: '"palt" 1',
    '> textarea::placeholder': {
      fontSize: '16px',
      color: Color.black
    }
  },
  '& label.Mui-focused': {
    color: 'green',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'green',
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      // borderColor: Color.orange,
    },
  },
})