import styled from "@emotion/styled"
import * as React from "react"

import MCheckbox from '@mui/material/Checkbox';
import { Link } from "gatsby";
import {  addAlpha, Color } from "../../../util/Color";

const CheckBox = ({onChange}) => {
  return (
    <Wrap>
      <MCheckbox onChange={onChange} sx={{
        color: Color.orange,
        ':hover': {
          background: addAlpha(Color.orange, 0.1),
        },
        '&.Mui-checked': {
          color: Color.orange,
        },
      }}/>
      <Label><Link to="/privacy">プライバシーポリシー</Link>に同意</Label>
    </Wrap>
  )
}

export default CheckBox

const Label = styled('div')({
  color: Color.black,
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
})

const Wrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
})
