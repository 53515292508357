import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Title from '../components/feature/Contact/Title'
import CenterTitle from '../components/feature/Home/CenterTitle'
import { Color } from '../util/Color'

import styled from "@emotion/styled"
import TextField from "../components/feature/Contact/TextField"
import MobileTextField from "../components/feature/Contact/MobileTextField"
import DropDown from "../components/feature/Contact/DropDown"
import MobileDropDown from "../components/feature/Contact/MobileDropDown"
import CheckBox from "../components/feature/Contact/CheckBox"
import MobileCheckBox from "../components/feature/Contact/MobileCheckBox"
import { Button } from "@mui/material"

const description = ''

const Contact = () => {

  const [companyName, setCompanyName] = React.useState('')
  const [name, setName] = React.useState('')
  const [mail, setMail] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [type, setType] = React.useState('')
  const [detail, setDetail] = React.useState('')
  const [agreement, setAgreement] = React.useState(false)
  const [isSended, setIsSended] = React.useState(false)

  const [view, setView] = React.useState('PC')

  React.useEffect(() => {
    const w = window.innerWidth
    setView((() => {
      if (1440 < w) return "PC"
      else if (960 < w) return "TABLET"
      else return "MOBILE"
    })())
    console.log({w})
    console.log({view})
  })

  const submit = () => {

    if (isSended) return alert('送信済みです')

    if (!agreement) return alert('プライバシーポリシーに同意してください')

    if (!companyName || !name || !mail || !phone || !type || !detail) return alert('全項目記入してください')

    if (!isMail()) return alert('メールアドレスを正しく入力してください')

    const params = {
      "company": companyName,
      "name": name,
      "email": mail,
      "phone": phone,
      "subject": type,
      "message": detail
    }
    const xhr = new XMLHttpRequest()
    xhr.open("POST", "https://lmy06keljf.execute-api.us-west-2.amazonaws.com/v1/upload");
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.send(JSON.stringify(params));
    xhr.onload = () => {
      console.log(xhr.status);
      console.log("success!");
    };
    xhr.onerror = () => {
      console.log(xhr.status);
      console.log("error!");

    };
    alert("お問い合わせが完了致しました。");
    setIsSended(true)
  }

  const isMail = () => {
    return true
  }

  if (view === 'MOBILE') {
    return (
      <Layout>
        <Seo title="Contact" description={description}/>
        <CenterTitle en="CONTACT" ja="お問い合わせ" />

        <Text view={view}>ブロックチェーンの開発・ソリューション、</Text>
        <Text view={view}>PLMの導入支援、Juggleに関する</Text>
        <Text view={view}>お問い合わせ、ご相談、ご質問等も承っています。</Text>
        <Text view={view}>お気軽にお問い合わせください。</Text>

        <FormWrapper view={view}>
          <MobileTextField label="会社名" setText={setCompanyName}/>
          <MobileTextField label="お名前" setText={setName} />
          <MobileTextField label="メールアドレス" setText={setMail} />
          <MobileTextField label="電話番号" setText={setPhone} />
          <MobileDropDown label="お問い合わせ種類" setText={setType} text={type}/>
          <MobileTextField label="お問い合わせ内容詳細" setText={setDetail} row={8} multiline/>
          <MobileCheckBox onChange={e => setAgreement(e.target.checked)}/>
          <ButtonWrapper>
            <SButton variant="outlined" onClick={submit}>送信</SButton>
          </ButtonWrapper>
        </FormWrapper>

      </Layout>
    )
  }

  return (
    <Layout>
      <Seo title="Contact" description={description}/>
      <Margin />
      <Title en="CONTACT" ja="お問い合わせ" />


      <Text view={view}>ブロックチェーンの開発・ソリューション、PLMの導入支援、Juggleに関する</Text>
      <Text view={view}>お問い合わせ、ご相談、ご質問等も承っています。お気軽にお問い合わせください。</Text>

      <FormWrapper view={view}>
        <TextField label="会社名" setText={setCompanyName} />
        <TextField label="お名前" setText={setName} />
        <TextField label="メールアドレス" setText={setMail} />
        <TextField label="電話番号" setText={setPhone} />
        <DropDown label="お問い合わせ種類" setText={setType} text={type}/>
        <TextField label="お問い合わせ内容詳細" setText={setDetail} row={8} multiline/>
        <CheckBox onChange={e => setAgreement(e.target.checked)}/>
        <ButtonWrapper>
          <SButton variant="outlined" onClick={submit}>送信</SButton>
        </ButtonWrapper>
      </FormWrapper>

    </Layout>
  )
}

export default Contact

const Margin = styled('div')({
  height: '54px',
  width: '1px',
})

const Text = styled('div')(({view}) => ({
  fontSize: view === 'MOBILE' ? '14px' : '18px',
  color: Color.black,
  fontFamily: 'Noto Sans JP',
  fontFeatureSettings: '"palt" 1',
}))


const FormWrapper = styled('div')(({view}) => ({
  width: view === 'MOBILE' ? 'calc(100vw - 48px)' : '800px'
}))

const SButton = styled(Button)({
  borderRadius: '200px',
  fontWeight: 700,
  color: Color.black,
  borderColor: Color.orange,
  ":hover": {
    borderColor: Color.orange,
    background: Color.orange,
    color: 'white'
  },
  ":active": {
    borderColor: Color.orange
  }
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  '> button' : {
    fontFamily: 'Noto Sans JP',
    padding: '6px 40px'
  }
})
